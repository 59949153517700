@import url('https://fonts.googleapis.com/css2?family=Nunito:ital,wght@1,600&display=swap');

.contact-page {
  font-family: 'Nunito', sans-serif;
  text-align: center;
  margin-top: 60px;
  width: 90vw;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 2fr;
}

.contact-me-details {
  grid-row: 2;
  grid-column: 1;
}

.contact-page-images {
  grid-row: 1;
  grid-column: 1/3;
}

.contact-page-image {
  padding-left: 20px;
}

.contact-form-section {
  grid-row: 2;
  grid-column: 2;
}

.contact-page-images img {
  height: 200px;
}

.contact-list {
  list-style: none;
  width: 300px;
  text-align: left;
  padding-inline-start: 5px;
  color: #00A7E1;
  font-weight: bold;
}

.contact-list-item:first-child {
  padding-top: 20px;
}

.contact-list-item:last-child {
  padding-bottom: 20px;
}

.contact-list-item {
  margin-top: 20px;
}

.contact-icon {
  height: 20px;
}

.contact-title {
  font-size: 35px;
  color: #E9E9E9;
}

.contact-me-text {
  font-size: 18px;
  color: #4EB1BA;
}

.contact-me-form-section {
  width: 80%;
  justify-self: center;
}

label {
  color: #00A7E1;
}

input[type=text], select, textarea {
  width: 100%; /* Full width */
  padding: 12px; /* Some padding */
  border: 1px solid #ccc; /* Gray border */
  border-radius: 4px; /* Rounded borders */
  box-sizing: border-box; /* Make sure that padding and width stays in place */
  margin-top: 6px; /* Add a top margin */
  margin-bottom: 16px; /* Bottom margin */
  resize: vertical /* Allow the user to vertically resize the textarea (not horizontally) */
}

input[type=submit] {
  color: white;
  padding: 12px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  background-color: #00A7E1;
}

input[type=submit]:hover {
  background-color: #45a049;
}

/* Add a background color and some padding around the form */
.container {
  border-radius: 5px;
  background-color: #f2f2f2;
  padding: 20px;
}
