.App {
  border-top: 6px solid #4EB1BA;
  height: 100vh;
  width: 100vw;
  display: grid;
  justify-content: space-around;
  top: 0;
  left: 0;
  position: absolute;
  background-color: #222930;
  overflow: scroll;
  color: #4EB1BA;
}


#nav {
  background-color: #222930;
  grid-row: 1;
  width: 100vw;
  position: absolute;
  border-bottom: solid 5px white;
  min-width: 1000px;
}

.about-me {
  justify-content: center;
}

.main-content {
  margin-top: 30px;
  margin-left: 20px;
  margin-right: 20px;
}
