@import url('https://fonts.googleapis.com/css2?family=Nunito:ital,wght@1,600&display=swap');


.about-me-main {
  margin-top: 50px;
  display: grid;
  font-family: 'Nunito', sans-serif;
  font-size: 20px;
  text-align: center;
  height: 80%;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1.8fr;
  width: 90vw;
}

.about-me-text {
  grid-row: 1;
  grid-column: 1/3;
}

.about-unknown {
  grid-row: 2;
  grid-column: 1;
  border-right: 1px solid white;
}

.hobbies-text {
  grid-row: 2;
  grid-column: 2;
}

.hobbies-header {
  font-size: 25px;
  color: #E9E9E9;
}

.hobbies-text {
  font-size: 18px;
  margin: 10px;
}
