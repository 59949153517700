.navbar-list{
  display: block;
  list-style: none;
  margin: 8px;
  padding: 8px;
  width: 50%;
  color: #4EB1BA;
  float: right;
}

.navbar-list-item{
  float: left;
  padding: 8px 16px;
  text-align: center;
  text-decoration: inherit;
  color: inherit;
}

li:last-child {
  border-bottom: none;
}

.navbar-list-item:hover{
  color: red;
}

.active{
    color: #EAE0D5;
  }

.title {
  margin-left: 40px;
  position: absolute;
}

.my-name {
  color: #E9E9E9;
  font-size: 4vh;
}

.my-title {
  color: #4EB1BA;
}
