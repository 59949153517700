@import url('https://fonts.googleapis.com/css2?family=Nunito:ital,wght@1,600&display=swap');

.home-page-content {
  height: 100vh;
  width: 100vw;
  text-align: center;
  display: grid;
  grid-template-rows: 1fr 6fr;
  margin-top: 30px;
  font-family: 'Nunito', sans-serif;
}

.intro {
  grid-row: 1;
  height: 90%;
  text-align: center;
  font-size: 25px;
  align-self: center;
  margin-left: 40px;
  margin-right: 40px;
  padding-top: 30px;
  border-bottom: solid 1px E9E9E9;
}

.title-in-text {
  font-size: 30px;
  font-weight: bold;
  color: #E9E9E9;
}

.tech-and-languages {
  grid-row: 2;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
  width: 80%;
  height: 50vh;
  overflow: scroll;
  justify-self: center;
  margin-top: 50px;
}

.logo {
  height: 100px;
  padding: 25px;
}
